<template>
    <div>

        <div v-if="param === 'step'">
            <p class="help help-mod">
                <span v-html="$t('Please make sure your security token is connected to a personal computer and')"></span>
                <router-link :to="{path: '/instructions', name: 'instructions', params: {step: 'SmartCard'}}"><b> Smart Card</b></router-link>
                {{ $t('service is running. If the Smart Card service is not running, after its launch, you must restart your browser') }}
            </p>
        </div>

        <div v-if="param === 'KeyCheckStepOne'">
            <p><strong>{{ $t('Security token not found or not supported') }}</strong></p><div style="height: 0.75em;"></div>
            <p><span v-html="$t('Please make sure your security token is connected to a personal computer and')"></span>
                <router-link :to="{path: '/instructions', name: 'instructions', params: {step: 'SmartCard'}}"><b> Smart Card</b></router-link>
                {{ $t('service is running. If the Smart Card service is not running, after its launch, you must restart your browser') }}
            </p>
        </div>

    </div>
</template>

<script>
export default {
  name: 'TokenHelpPage',
  props: [
    'param'
  ]
}
</script>
